<template>
	<div id="app">
		<background/>
		<div id="content">
			<router-view role="application"></router-view>
			<keep-alive>
				<component :is="gameClientComponent"/>
			</keep-alive>
			<keep-alive>
				<api-client></api-client>
			</keep-alive>
		</div>
		<debug-panel v-if="showDebugPanel"></debug-panel>
		<!-- <version-info></version-info> -->
		<transition name="fade">
			<error-container v-if="errorMessage" :errorIcon="errorIcon" v-on:click.native="onErrorDismiss">
				{{ errorMessage }}
			</error-container>
		</transition>
	</div>
</template>

<script>
import Background from '@/components/background'

import GameClientOnline from '@/components/app-components/game-client'
import GameClientOffline from '@/components/app-components/game-client-offline'

import ApiClient from '@/components/app-components/api-client'
import DebugPanel from '@/components/debug/debug-panel'
import VersionInfo from '@/components/version-info'
import {$eBus} from '@/event-bus/index'
import GameClientEvent from '@/enum/GameClientEvent'
import GameClientType from '@/enum/GameClientType'
import ErrorContainer from '@/components/error-container'
import AppConfig from '@/app-config'

import * as MutationTypes from '@/store/mutation-types'
import {mapGetters} from 'vuex'
import { getStudentUrl } from './utils/UrlUtil'
import { getLanguages } from './utils/LanguageUtil'

export default {
	name: 'app',
	components: {
		Background,
		'game-client-online': GameClientOnline,
		'game-client-offline': GameClientOffline,
		DebugPanel,
		ApiClient,
		ErrorContainer,
		VersionInfo,
	},

	created() {
		let hostname = window.location.hostname

		// This requires devServer.disableHostCheck in the config!!!
		let languageMatchUrl = hostname.match(/^([a-z]{2})\./)

        let useLogin = false;

		if (!useLogin) {
            this.$store.commit(MutationTypes.SET_TEACHER_PASSWORD, process.env.VUE_APP_TEACHER_PASSWORD);
        }

		const useLanguageSelector = process.env.hasOwnProperty('VUE_APP_USE_LANGUAGE_SELECTOR') ?
            parseInt(process.env.VUE_APP_USE_LANGUAGE_SELECTOR, 10) > 0 : false;

		let languageMatch = useLanguageSelector ? null : 'en';

        const languages = getLanguages();

		if (languageMatchUrl && languageMatchUrl.length >= 2 && languageMatchUrl[1]) {
			languageMatch = languageMatchUrl[1]
		}

		if (languageMatch) {
            for (let language of languages) {
                if (languageMatch === language.code) {
                    console.log('Changing locale/language to "' + language.code + '"')

                    this.$i18n.locale = language.code
                    this.$root.$i18n.locale = language.code

                    this.$store.commit(MutationTypes.SET_LANGUAGE, language.code)
                }
            }
        }
    },
	mounted() {
		$eBus.$on(GameClientEvent.Close, this.onGameClientClose)

		$eBus.$on(GameClientEvent.CaseInfoChanged, this.onCaseInfoChanged)
		$eBus.$on(GameClientEvent.CaseCurrentPageChanged, this.onCasePageChanged)
	},
	beforeDestroy() {
		$eBus.$off(GameClientEvent.Close, this.onGameClientClose)

		$eBus.$off(GameClientEvent.CaseInfoChanged, this.onCaseInfoChanged)
		$eBus.$off(GameClientEvent.CaseCurrentPageChanged, this.onCasePageChanged)
	},
	methods: {
		onCaseInfoChanged(caseInfo, changes) {
			console.log('onCaseInfoChanged', caseInfo)

			this.$store.commit(MutationTypes.SET_CURRENT_CASE, {
				id: caseInfo.id,
				description: caseInfo.description,
				image: caseInfo.image,
				state: caseInfo.state,
				static: caseInfo.static,
				themeId: caseInfo.themeId,
				title: caseInfo.title,
			})
		},
		onCasePageChanged(casePage, changes) {
			console.log('onCasePageChanged', casePage)

			this.$store.commit(MutationTypes.SET_CURRENT_PAGE, casePage)

			if (casePage && casePage.components) {
				let choices, choiceComponentSide

				console.log('casePage.components', casePage.components)

				if (casePage.component_left && casePage.component_left.component === 'Choices') {
					choices = casePage.component_left.options.choices
					choiceComponentSide = 'left'
				} else if (casePage.component_right && casePage.component_right.component === 'Choices') {
					choices = casePage.component_right.options.choices
					choiceComponentSide = 'right'
				}

				if (choices) {
					console.log('added choice listener', choices)

					choices.onAdd = (choice) => {
						if (choice.dynamic) {
							console.log('onAdd.choice', choice)

							this.$store.commit(MutationTypes.ADD_CURRENT_PAGE_CHOICE, {
								choice: choice,
								side: choiceComponentSide,
							})
						}
					}
				}
			}

			/*choiceComponent.options.choices.onAdd = (choice) => {
			  this.options.choices.push(choice);
			};*/
		},
		onGameClientClose() {
			let currentRoutePage = (this.$router.currentRoute && this.$router.currentRoute.name)
				? this.$router.currentRoute.name
				: null

			let redirectUrl = this.gameClientCloseRedirectUrl

			if (!redirectUrl) {
				if (currentRoutePage === 'StudentPage') {
					redirectUrl = getStudentUrl('connect');
				} else {
					redirectUrl = '/'
				}
			}

			// Make sure we disable navigation guard because something obviously went wrong
			this.$store.commit(MutationTypes.SET_NAVIGATION_GUARD_ENABLED, false)
            this.$store.dispatch({type: 'fullReset'})

			console.log('Game client connection was closed - redirecting to ' + redirectUrl)

            this.$nextTick(() => {
                // If the game client connection closes, let's go back to the index page
                this.$router.push(redirectUrl)
            })
		},
		onErrorDismiss() {
			this.$store.commit(MutationTypes.CLEAR_ERROR_MESSAGE)
		},
	},
	computed: {
		...mapGetters({
			gameClientType: 'getGameClientType',
			gameClientCloseRedirectUrl: 'getGameClientCloseRedirectUrl',
			errorMessage: 'getErrorMessage',
			errorIconType: 'getErrorIcon',
			currentTheme: 'getCurrentTheme',
		}),
		gameClientComponent() {
			if (this.gameClientType === GameClientType.Online) {
				return 'game-client-online'
			} else if (this.gameClientType === GameClientType.Offline) {
				return 'game-client-offline'
			} else if (this.gameClientType) {
				console.error(`Unknown game client type "${this.gameClientType}"`)
			}

			return null
		},
		errorIcon() {
			if (this.errorIconType === 'connectionError') {
				return 'misc/connection-error.png'
			}

			return 'misc/error.png'
		},
		showDebugPanel() {
			return AppConfig.showDebugPanel
		},
	},
}
</script>

<style lang="less">
@import "~@less/main.less";
@import "~@less/variables.less";

html, body {
	height: 100%;
	overflow: hidden;
}

#app {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
}
</style>
