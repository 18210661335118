var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("background"),
      _c(
        "div",
        { attrs: { id: "content" } },
        [
          _c("router-view", { attrs: { role: "application" } }),
          _c(
            "keep-alive",
            [_c(_vm.gameClientComponent, { tag: "component" })],
            1
          ),
          _c("keep-alive", [_c("api-client")], 1),
        ],
        1
      ),
      _vm.showDebugPanel ? _c("debug-panel") : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.errorMessage
            ? _c(
                "error-container",
                {
                  attrs: { errorIcon: _vm.errorIcon },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onErrorDismiss.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n\t\t\t" + _vm._s(_vm.errorMessage) + "\n\t\t")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }